import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocalizedTheme } from 'config/theme';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import numeral from 'numeral';
import 'numeral/locales/fr';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useCurrentLanguage } from 'shared/hooks/auth-hook';

import { ThemeProvider } from '@mui/material/styles';
import { useEffectOnce } from 'react-use';
import { initSession } from 'shared/reducers/authSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import router from 'shared/routes/route';
import { getShortFormat } from 'shared/utils/locale-utils';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.extend(relativeTime);

dayjs.locale('fr');

const LocalizedApp = () => {
  const dispatch = useAppDispatch();
  const currentLocal = useCurrentLanguage();
  const emTheme = useLocalizedTheme();

  useEffectOnce(() => {
    dispatch(initSession(true));
  });

  useEffect(() => {
    const shortLocal = getShortFormat(currentLocal);
    if (currentLocal.includes('fr')) {
      dayjs.locale('fr');
    }
    numeral.locale(shortLocal);
  }, [currentLocal]);

  return (
    <ThemeProvider theme={emTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocal}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default LocalizedApp;
