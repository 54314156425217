import DOMPurify from 'dompurify';
import { useGetServerInfoQuery } from 'services/endpoints/DirectusEndpoints';

const url = import.meta.env.VITE_DIRECTUS_URL;

export const useDirectusAppLogoUrl = () => {
  const { data } = useGetServerInfoQuery();
  const logoId = data?.project_logo;
  if (logoId) {
    return `${url}/assets/${logoId}`;
  }
  return 'img/logo.png';
};

export const useDirectusAppPublicBackgroundUrl = () => {
  const { data } = useGetServerInfoQuery();
  const logoId = data?.public_background;
  if (logoId) {
    return `${url}/assets/${logoId}`;
  }
  return 'img/logo.png';
};

export const useDirectusAppPublicForeGroundUrl = () => {
  const { data } = useGetServerInfoQuery();
  const logoId = data?.public_foreground;
  if (logoId) {
    return `${url}/assets/${logoId}`;
  }
  return 'img/logo.png';
};

export const useDirectusDefaultLanguage = () => {
  const { data } = useGetServerInfoQuery();

  const language = data?.default_language;
  return language ?? 'fr-FR';
};

export const useDirectusProjectDescriptor = () => {
  const { data } = useGetServerInfoQuery();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const language = data?.project.public_note as string;
  return language ?? 'fr-FR';
};

export const useDirectusProjectNote = () => {
  const { data } = useGetServerInfoQuery();

  return data?.public_note ? DOMPurify.sanitize(data?.public_note) : '';
};
