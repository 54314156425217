import { customEndpoint } from '@directus/sdk';
import { SearchResponse } from '@elastic/elasticsearch/lib/api/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import directus from 'config/directus';
import { userSearchApi } from 'services/endpoints/UserSearchEndpoints';
import { EscapeSearch } from 'shared/types/EscapeSearch.model';

const directusUrl = import.meta.env.VITE_DIRECTUS_URL;

const path = '/flows/trigger/f4d9de1f-681d-489d-b99c-5b6ee7f8ab08';
const url = directusUrl + path;

export const searchApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: url }),
  endpoints: builder => ({
    search: builder.query<SearchResponse<any>, EscapeSearch>({
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(userSearchApi.util.invalidateTags(['userSearchs']));
      },
      queryFn: async q => {
        const data = await directus.request<SearchResponse<any>>(
          customEndpoint({
            method: 'POST',
            path,
            body: JSON.stringify(q.search),
            params: {
              index: q.index,
            },
          }),
        );

        return { data };
      },
    }),
  }),
  reducerPath: 'searchApi',
});

export const { useSearchQuery } = searchApi;

export default searchApi;
