import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactNode, useRef } from 'react';
import { closeDrawer, openDrawer } from 'shared/reducers/drawerSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  type?: 'button' | 'icon' | 'menuItem' | 'txtButton';
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  icon?: ReactNode;
  text?: string;
  paperSx?: SxProps<Theme>;
  onClick?: () => void;
  children: ReactNode;
};

const OpenDrawerButton = ({
  type = 'icon',
  icon = <EditIcon />,
  text,
  anchor = 'right',
  paperSx = {
    width: '100%',
    maxWidth: '800px',
  },
  children,
}: Props) => {
  const dispatch = useAppDispatch();
  const drawerStates = useAppSelector(state => state.drawer);
  const contentId = useAppSelector(state => state.drawer.contentId);
  const ref = useRef(uuidv4());

  const open = drawerStates[anchor] && contentId === ref.current;

  const onClick = () => {
    dispatch(openDrawer(anchor, ref.current));
  };

  const onClose = () => {
    dispatch(closeDrawer(anchor));
  };

  return (
    <>
      {type === 'button' ? (
        <Button color="primary" onClick={onClick}>
          {icon}
        </Button>
      ) : type === 'txtButton' ? (
        <Button color="primary" onClick={onClick}>
          {text}
        </Button>
      ) : type === 'menuItem' ? (
        <MenuItem onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </MenuItem>
      ) : (
        <IconButton color="primary" onClick={onClick}>
          {icon}
        </IconButton>
      )}
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: paperSx,
        }}
        ModalProps={{
          keepMounted: false,
        }}
      >
        {children}
      </Drawer>
    </>
  );
};

export default OpenDrawerButton;
