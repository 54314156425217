import CategoryIcon from '@mui/icons-material/Category';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import WebIcon from '@mui/icons-material/Web';
import { Box, Grid2 as Grid } from '@mui/material';
import { isObject } from 'lodash';
import { Link } from 'react-router-dom';
import DisplayHtml from 'shared/components/DisplayHtml';
import IconValue from 'shared/components/IconValue';
import ModelName from 'shared/model/def/ModelName';
import { StructureIcon } from 'shared/model/def/structure.def';
import { getTeamAdress } from 'shared/model/def/team.def';
import { Geosite, Person, Structure, Team, TypeEquipe } from 'shared/model/types';
import { disablePropagation } from 'shared/utils/event-utils';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  team: Team;
  noDescription?: boolean;
};

const SearchResultTeam = ({ team, noDescription = false }: Props) => {
  const person = team.person as Person | undefined;
  const geosite = team.geosite as Geosite | undefined;
  const typeEquipe = team.type as TypeEquipe | undefined;
  const structure = team.structure as Structure | undefined;

  const address = getTeamAdress(team);

  const md = noDescription ? 12 : 6;
  const lg = noDescription ? 12 : 4;
  const xl = noDescription ? 12 : 4;

  return (
    <Grid container alignItems="flex-start" spacing={1} fontSize="0.9rem">
      <Grid
        size={{
          xs: 12,
          md: md,
          lg: lg,
          xl: xl,
        }}
      >
        {person && (
          <IconValue icon={<PersonIcon fontSize="small" />}>
            <Box pt={0.2} onClick={disablePropagation}>
              <Link to={`/sheet/person/${person.id}/main`}>
                <ModelName model={person} type="person" />
                {isObject(team.responsibleFunction) && ' (' + team.responsibleFunction.name + ')'}
              </Link>
            </Box>
          </IconValue>
        )}
        {structure && (
          <IconValue icon={<StructureIcon fontSize="small" />}>
            <Box pt={0.2} onClick={disablePropagation}>
              <Link to={`/sheet/structure/${structure.id}/main`}>
                <ModelName model={structure} type="structure" />
              </Link>
            </Box>
          </IconValue>
        )}
        {typeEquipe && (
          <IconValue icon={<CategoryIcon fontSize="small" />}>
            <Box pt={0.2}>{typeEquipe.name}</Box>
          </IconValue>
        )}
        {geosite && (
          <IconValue icon={<HomeIcon fontSize="small" />}>
            <Box pt={0.2}>{geosite.name}</Box>
          </IconValue>
        )}
        {team.web_site && (
          <IconValue icon={<WebIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <a href={team.web_site} target="_blank">
                {team.web_site}
              </a>
            </Box>
          </IconValue>
        )}
        {team.email && (
          <IconValue icon={<EmailIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <a href={'mailto:' + team.email}>{team.email}</a>
            </Box>
          </IconValue>
        )}
        {team.phone && (
          <IconValue icon={<PhoneIcon fontSize="small" />}>
            <Box pt={0.2}>{team.phone}</Box>
          </IconValue>
        )}

        {team.fax && (
          <IconValue icon={<FaxIcon fontSize="small" />}>
            <Box pt={0.2}>{team.fax}</Box>
          </IconValue>
        )}

        {address && (
          <IconValue icon={<PlaceIcon fontSize="small" />}>
            <Box pt={0.2}>{address}</Box>
          </IconValue>
        )}
      </Grid>
      {!noDescription && (
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 8,
            xl: 8,
          }}
        >
          {team.shortDescription && stringHtmlHasContent(team.shortDescription) && (
            <DisplayHtml html={team.shortDescription} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultTeam;
