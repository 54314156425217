import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, ToggleButton } from '@mui/material';
import dayjs from 'dayjs';
import { isObject } from 'lodash';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useGetTeamMembers } from 'modules/sheet/sheetHook';
import AddTeamMember from 'modules/sheet/team/teamMember/AddTeamMember';
import DeleteTeamMembersBtn from 'modules/sheet/team/teamMember/DeleteTeamMembersBtn';
import EditTeamMember from 'modules/sheet/team/teamMember/EditTeamMember';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { TeamMember } from 'shared/model/types';
import { DATE_FORMAT } from 'shared/utils/date-utils';

const columnHelper = createMRTColumnHelper<TeamMember>();

const TeamMembersTab = () => {
  const { t } = useTranslation();
  const defaultMRTOptions = useGetDefaultMRTOptions<TeamMember>();
  const { id } = useParams<{ id: string }>();

  const columns = useMemo(() => {
    const result = [
      /*columnHelper.accessor('id', {
        header: 'ID',
      }),*/
      columnHelper.accessor('person.firstName', {
        header: t('firstName'),
        grow: 1,
        Cell({ cell, row }) {
          const value = cell.getValue();
          if (value && isObject(row.original.person)) {
            return <Link to={`/sheet/person/${row.original.person.id}/main`}>{value}</Link>;
          }
          return null;
        },
      }),
      columnHelper.accessor('person.lastName', {
        header: t('lastName'),
        grow: 1,
        Cell({ cell, row }) {
          const value = cell.getValue();
          if (value && isObject(row.original.person)) {
            return <Link to={`/sheet/person/${row.original.person.id}/main`}>{value}</Link>;
          }
          return null;
        },
      }),
      columnHelper.accessor('label', {
        header: t('label'),
        grow: 2,
      }),
      columnHelper.accessor('person.phone', {
        header: t('phone'),
        grow: 1,
        maxSize: 100,
      }),
      columnHelper.accessor('person.portable', {
        header: t('portable'),
        grow: 1,
        maxSize: 100,
      }),
      columnHelper.accessor('person.email', {
        header: t('email'),
        grow: 1,
        Cell({ cell }) {
          const email = cell.getValue();
          if (email) {
            return <a href={`mailto:${email}`}>{email}</a>;
          }
          return null;
        },
      }),
      columnHelper.accessor('metier.name', {
        header: t('metier'),
        grow: 1,
      }),
      columnHelper.accessor('statut.name', {
        header: t('status'),
        grow: 1,
      }),
      columnHelper.accessor('startDate', {
        header: t('startDate'),
        grow: 1,
        filterVariant: 'date-range',
        filterFn: 'lessThan',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (value) {
            return dayjs(value).format(DATE_FORMAT);
          }
          return '';
        },
      }),
      columnHelper.accessor(row => dayjs(row.endDate), {
        id: 'endDate',
        header: t('endDate'),
        grow: 1,
        filterVariant: 'date-range',
        filterFn: 'dateNotExists',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (value.isValid()) {
            return dayjs(value).format(DATE_FORMAT);
          }
          return '';
        },
      }),
    ];
    return result;
  }, [t]);

  const { data, isLoading } = useGetTeamMembers();

  const members = data ?? [];

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    renderTopToolbarCustomActions: ({ table }) => {
      const selection = table.getSelectedRowModel().flatRows.map(row => row.original);

      const endDateColumn = table.getColumn('endDate');

      const filtered = endDateColumn.getIsFiltered();

      return (
        <>
          <Box flexGrow={1}>
            {selection.length === 0 ? (
              <AddTeamMember teamId={Number(id)} />
            ) : selection.length === 1 ? (
              <>
                <EditTeamMember teamMember={selection[0]} />
                <DeleteTeamMembersBtn teamMembers={selection} />
              </>
            ) : (
              <Box>
                <DeleteTeamMembersBtn teamMembers={selection} />
              </Box>
            )}
          </Box>
          <Box>
            <ToggleButton
              size="small"
              value="check"
              selected={filtered}
              onChange={() => {
                if (endDateColumn.getIsFiltered()) {
                  endDateColumn?.setFilterValue(undefined);
                } else {
                  endDateColumn?.setFilterValue(dayjs());
                }
              }}
            >
              <VisibilityOffIcon />
              <Box ml={1}>{t('members.filterOldMembers')}</Box>
            </ToggleButton>
          </Box>
        </>
      );
    },
    state: {
      isLoading,
      showProgressBars: isLoading,
    },
    initialState: {
      ...defaultMRTOptions.initialState,
      columnFilters: [
        {
          id: 'endDate',
          value: dayjs(),
        },
      ],
    },
    columns,
    data: members,
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default TeamMembersTab;
