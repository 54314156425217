import Point from '@arcgis/core/geometry/Point';
import { Box, Card, CardContent, Grid2 as Grid, Tab, Tabs } from '@mui/material';
import SearchResultStructure from 'modules/search/results/SearchResultStructure';
import { useGetStructure } from 'modules/sheet/sheetHook';
import DeleteStructuresBtn from 'modules/sheet/structure/DeleteStructuresBtn';
import EditStructureBtn from 'modules/sheet/structure/EditStructureBtn';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUpdateStructureMutation } from 'services/endpoints/StructureEndpoints';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';
import { useSaveHistory } from 'shared/hooks/historyHook';
import ModelName from 'shared/model/def/ModelName';
import { StructureIcon } from 'shared/model/def/structure.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const tabs = [
  {
    label: 'sheetStructure.tabMain',
    path: 'main',
  },
  {
    label: 'sheetStructure.tabPresentation',
    path: 'presentation',
  },
  {
    label: 'team.team_other',
    path: 'teams',
  },
];

type Props = {
  children: React.ReactNode;
};

const StructureSheet = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateStructure] = useUpdateStructureMutation();

  const { data, isLoading } = useGetStructure();

  useSaveHistory('structure', data?.id, data?.name);

  const match = useMatch('/sheet/structure/:id/:tab');

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const tab = match?.params.tab;
  const tabValue = tabs.findIndex(item => item.path === tab) ?? 0;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const path = tabs[newValue].path;
    navigate(path);
  };

  const onDeleted = () => {
    navigate('/search');
  };

  const onSaveGeom = async (point: Point) => {
    updateStructure({
      id: data.id,
      item: {
        geom: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      },
    }).unwrap();
    dispatch(closeAllDrawers());
  };

  return (
    <Box px={1}>
      <Box pb={1}>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconValue icon={<StructureIcon fontSize="large" />}>
                      <ModelName model={data} type="structure" fontSize={24} />
                    </IconValue>
                  </Box>
                  <EditStructureBtn item={data} />
                  <DeleteStructuresBtn ids={[data.id]} onDeleted={onDeleted} />
                </Box>
                <SearchResultStructure structure={data} noDescription />
              </Grid>
              <Grid>
                <ShowAndEditGeom point={data.geom} onSave={onSaveGeom} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Box borderBottom={1} borderColor="divider">
              <Tabs value={tabValue} onChange={handleChange}>
                {tabs.map(tab => (
                  <Tab key={tab.path} label={t(tab.label)} />
                ))}
              </Tabs>
            </Box>
            <Box pt={1}>{children}</Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default StructureSheet;
