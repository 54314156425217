import { zodResolver } from '@hookform/resolvers/zod';
import { isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StructureFormType } from 'shared/model/def/structure.def';

import { Geosite, Person, StructureType } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useStructureResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');
  const emailMessage = t('form.errors.emailPattern');

  const schema = z.object({
    name: z.string().min(1, requiredMessage),
    geosite: z.custom<Geosite>(value => isObject(value), { message: requiredMessage }),
    shortName: z.string().optional().or(z.literal('')).or(z.null()),
    address: z.string().optional().or(z.literal('')).or(z.null()),
    address_2: z.string().optional().or(z.literal('')).or(z.null()),
    cp: z.string().optional().or(z.literal('')).or(z.null()),
    city: z.string().optional().or(z.literal('')).or(z.null()),
    responsible: z.custom<Person>(value => isObject(value), { message: requiredMessage }),
    responsibleFunction: z.custom<Person>(value => isObject(value) || !value),
    phone: z.string().optional().or(z.literal('')).or(z.null()),
    fax: z.string().optional().or(z.literal('')).or(z.null()),
    email: z.string().email({ message: emailMessage }).or(z.null()).or(z.literal('')),
    webSite: z.string().optional().or(z.literal('')).or(z.null()),
    type: z.custom<StructureType>(value => isObject(value), { message: requiredMessage }),
  }) satisfies ZodType<StructureFormType>;

  return zodResolver(schema);
};
export default useStructureResolver;

export const useStructureCreationResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    name: z.string({ message: requiredMessage }),
  }) satisfies ZodType<StructureFormType>;

  return zodResolver(schema);
};
