import Box from '@mui/material/Box';
import {
  useDirectusAppPublicBackgroundUrl,
  useDirectusAppPublicForeGroundUrl,
  useDirectusProjectNote,
} from 'shared/hooks/directus-hook';

const HomePage = () => {
  const foregroundUrl = useDirectusAppPublicForeGroundUrl();
  const backgroundUrl = useDirectusAppPublicBackgroundUrl();
  const publicNote = useDirectusProjectNote();

  return (
    <Box position="relative">
      <Box display="flex" alignItems="center" mt={1} zIndex={2} position="absolute">
        {foregroundUrl && (
          <Box
            component="img"
            src={`${foregroundUrl}`}
            alt=""
            sx={{
              marginRight: 2,
              cursor: 'pointer',
            }}
          />
        )}
        {publicNote && <Box dangerouslySetInnerHTML={{ __html: publicNote }}></Box>}
      </Box>
      {backgroundUrl && (
        <Box
          component="img"
          src={`${backgroundUrl}`}
          alt=""
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer',
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default HomePage;
