import { isNumber, isObject, isString } from 'lodash';
import { Tutelle } from 'shared/model/types';

export const getTutelleItemName = (item: Partial<Tutelle>) => {
  const organization = item.organization;
  let str = '';

  if (isNumber(item.sort)) {
    str = item.sort.toString() + ' - ';
  }

  if (isObject(organization)) {
    str = organization.name ?? '';
  }

  if (isString(item.code) && item.code.length > 0) {
    str += ' - ' + item.code;
  }
  return str;
};
